<template>
  <div>
    <base-header class="pb-6">
      <b-row align-v="center" class="py-4">
        <b-col lg="6" cols="7">
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block">
            <route-bread-crumb :crumbs='generateBreadCrumb()'></route-bread-crumb>
          </nav>
        </b-col>
        <b-col lg="6" cols="5" class="text-right"> </b-col>
      </b-row>
    </base-header>
    <b-container fluid class="mt--6">
      <b-row>
        <b-col>
          <b-card no-body>
            <b-card-header class="border-0">
              <b-row>
                <b-col> </b-col>
                <b-col cols="3">
                  <form>
                    <b-form-group class="mb-0">
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <i class="fas fa-search" />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="search"
                          placeholder="Search"
                        ></b-form-input>
                      </b-input-group>
                    </b-form-group>
                  </form>
                </b-col>
              </b-row>
            </b-card-header>

            <b-table class=" w-100"
            header-row-class-name="thead-light"
            :busy.sync="isBusy"
            :filter="search"
            :per-page="perPage"
            :currentPage="currentPage"
            :items="provider"
            :fields="fields"
            >
            <template #cell(deviceId)="row">
              <div  style="text-align:center; width: 50px"> 
              <i
                style="cursor:pointer; color: blue"
                 @click="openModal(row.item)"
                class="fa-lg fas fa-mobile-alt"></i>
              </div>
              </template>

              <template #cell(actions)="row">
                <i
                style="cursor:pointer"
                 @click="openModal(row.item)"
                class="fa-lg fas fa-arrow-right"></i>
              </template>
            </b-table>
            <b-card-footer class="py-4 d-flex justify-content-end">
            <base-pagination @change="reloadRows" v-model="currentPage" :per-page="perPage" :total="total"></base-pagination>
        </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <modal @close="closeModal" :show="showModal">
    <b-overlay>
      <b-container fluid>
        <b-row>
          <b-col md="12">
            <h3>Device Id</h3>
             <div class="box">{{ modalData ?  modalData.deviceId : '' }}</div>
          </b-col>
         
        </b-row>
      </b-container>
    </b-overlay>
  </modal>
  
   

  </div>
</template>
<script>
//  import { Dropdown, DropdownItem, DropdownMenu, Table, TableColumn } from 'element-ui';
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import * as repo from "@/api-repo/notifications";
import { dateformatter } from "@/util/dateformatter";

export default {
  name:'devices-page',
  inject: ["userStore"],
  components: {
    RouteBreadCrumb,
  },
  data() {
    return {
      
      showModal: false,
      modalData: null,
      isBusy: false,
      currentPage: 1,
      perPage: 10,
      total:1,
      rows: [],
      search: null,
      fields: [
        { key: "guests.fullname.en", label: "Guest" },
        { key: "date", label: "Date", formatter: dateformatter },
        { key: "deviceId", label: "Device Id"},
        //{ key: "actions", label: "" },
      ],
    };
  },
  methods: {
    generateBreadCrumb() {
        return [{label:"Notifications"},{label: "Devices"}];
      },
    closeModal(){
      this.showModal = false;
    },
    reloadRows: function(e) {
      this.currentPage = e
    },
    openModal(data){
      this.showModal = true;
      this.modalData = data;
    },

    provider: async function(ctx) {
      this.isBusy = true;
      let result = await repo.getAllDevices(ctx.filter || '', ctx.currentPage, ctx.perPage, this.userStore.state.token);
      this.isBusy = false;
      this.currentPage = result.data.data[0].page + 1;
      this.total = result.data.data[0].total.length && result.data.data[0].total[0].count;
      return result.data.data[0].rows;
    },
  },
};
</script>


  <style scoped>
  .box {
    inline-size: 400px;
    overflow-wrap: break-word;
}
  
  </style>


     