import axios from "axios"


export const getAllDevices = (search, page, count, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `devices/?search=${search}&count=${count}&page=${page}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}


export const getDeviceById = (id, token) => {
  return axios.get(process.env.VUE_APP_API_URL + `devices/` + id, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
}

export const getDeviceByGroupId = (id, token) => {
    return axios.get(process.env.VUE_APP_API_URL + `devices/groupId/` + id, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }

  export const getAllQueuedMessages = (search, page, count, token) => {
    return axios.get(process.env.VUE_APP_API_URL + `pushnotification/?search=${search}&count=${count}&page=${page}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }

  export const getAllDoneMessages = (search, page, count, token) => {
    return axios.get(process.env.VUE_APP_API_URL + `pushnotification/history/?search=${search}&count=${count}&page=${page}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }

  export const createMessage = (guestId, text, reason,  token) => {
    console.log('create 1 message')
    const body = {text, reason}
    return axios.post(process.env.VUE_APP_API_URL + `pushnotification/guests/` + guestId, body, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }

  export const createMessages = (text, reason,  token) => {
    console.log('create messages')
    const body = {text, reason}
    return axios.post(process.env.VUE_APP_API_URL + `pushnotification/broadcast`, body,  {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    })
  }